/* Utilities */
.bgMain {
  background-color: var(--main);
}

.colorGreyLight {
  color: var(--grey-light);
}

.colorGrey {
  color: var(--grey);
}

.colorBlue {
  color: var(--blue);
}

.colorBlueLight {
  color: var(--blue-light);
}

.colorOrange {
  color: var(--orange);
}

.colorMain {
  color: var(--main);
}

.colorGreen {
  color: var(--green);
}

.bgGreen {
  background-color: var(--green);
}

.colorBlack {
  color: var(--dark);
}

.colorWhite {
  color: var(--light);
}

.font-16 {
  font-size: 1.6rem;
  letter-spacing: -0.2px;
  line-height: 2.4rem;
}

.font-14 {
  font-size: 1.4rem !important;
  // letter-spacing: -0.2px;
  line-height: 1.4rem !important;
}

.font-12 {
  font-size: 1.2rem !important;
}

.pointer {
  cursor: pointer;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-300 {
  font-weight: 300;
}

.iconWrapper {
  display: inline-block;
  width: 18px;
  height: 18px;

  &.large {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  &.small {
    width: 14px;
    height: 14px;
    line-height: 14px;
  }

  &.extraSmall {
    width: 12px;
    height: 12px;
    line-height: 12px;
    margin-top: -2px;
  }

  img,
  svg {
    max-width: 100%;
    width: auto;
    height: 100%;
  }



  &.filled {
    .toggle {
      transition: fill .2s linear, stroke .2s linear;
    }

    &:hover {
      .toggle {
        fill: var(--green-light);
        stroke: transparent;
      }
    }


  }

  &:not(.extraSmall):hover,
  &.active {
    .toggle {
      stroke: var(--green-light);
    }
  }
}

.p-relative {
  position: relative;
}

.strokeWhite {
  .toggle {
    stroke: white;
  }
}

.strokeGrey {
  .toggle {
    stroke: var(--grey);
  }
}

.fillWhite {
  .toggle {
    fill: white;
  }
}

.nonButton {
  background-color: transparent;
  border: none;
}

.mt-40 {
  margin-top: 40px;
}

.responsive-mb-40 {
  margin-bottom: 40px;

  @media (max-width: 896px) {
      margin-bottom: initial;
  }
}

.toggle {
  transition: fill .2s linear;
  transition: stroke .2s linear;
}

.shadow {
  box-shadow: 0 18px 60px -30px rgba(38, 50, 56, 0.2) !important;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}