:root {
    --main: #242F38;
   
    --blue: #145685;
    --blue-dark: #10446A;
    --blue-light: #1B8DB3;
    --mdc-theme-primary: #1B8DB3;
    
    --grey: #969899;
    --grey03: #cbcccc;

    --grey-light: #F3F3F3;
    --grey-medium: #F1F2F3;
    
    --red: #DD1F4A;
    --orange: #E67417;
    --green: #138057;
    --dark: #000000;
    --light: #ffffff;
   
    //   --grey: #AFAFB1;
    // --main: #263238;
    // --main-hover: #455347;
    // --grey: #AFAFB1;
    // --grey-dark: #263238;
    // --grey-dark2: #37383C;
    // --grey-light: #F3F3F3;
    // --grey-hover: #e7e7e7;
    // --line-grey: #DDDDDD;
    // --blue: #0020A2;
    // --red: #D22630;
    // --red-medium: #D55353;
    // --red-light: #D78787;
    // --green-light: #C4D787;
    // --green-dark: #98a971;
    // --green-light3: #B4C67E;
    // --green-hover: #b3c67e;
    // --green-dark2: #93a56f;

    //   --grey: #F5F5F5;
    // --grey: #E6E6E6;
    --header-height: 80px;
}